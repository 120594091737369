
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiModels } from "@/core/api";
import AddModelModal from "@/views/influencer-platform/capability-models/AddModel.vue";
import mixin from "@/mixins";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

interface FilterOption {
  field: string;
  value: number;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "capability-models-information",
  components: {
    AddModelModal,
  },
  setup() {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const modelId = ref(0);
    const modelsTableData = ref([]);
    const filterOptions = ref<Array<FilterOption>>([
      { field: "group_id", value: parseInt(route.params.id.toString()) },
    ]);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      status: 0,
      name: "",
      short_key: "",
      note: "",
      tag_ids: [],
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      short_key: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getModelGroupData = async () => {
      loading.value = true;
      const { data } = await ApiModels.getModelGroupInfo({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiModels.updateModelGroup(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteCapabilityModels = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate Capability Models?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiModels.deleteModelGroup({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Capability Models has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "capability-models" });
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const handleAddModel = () => {
      modelId.value = 0;
    };

    const handleEditModel = (index, row) => {
      modelId.value = row.id;
    };

    const updateModelList = async () => {
      loading.value = true;
      const { data } = await ApiModels.getModelList({
        filter_group: filterOptions.value,
      });
      loading.value = false;
      if (data.code == 0) {
        modelsTableData.value = data.data.items;
      }
    };

    const handleDeleteModel = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiModels.deleteModel({
            id: row.id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateModelList();
            });
          }
        }
      });
    };

    const getModelLayoutLabel = (row, column, cellValue, index) => {
      const layout = new Map([
        ["checkbox", t("common.checkbox")],
        ["switch", t("common.switch")],
        ["radio", t("common.radio")],
        ["radio-button", t("common.radioButton")],
        ["dropdown", t("common.dropdown")],
        ["dropdown-multiple", t("common.dropdownMultiple")],
        ["text", t("common.textInput")],
        ["textarea", t("common.textareaInput")],
        ["range", t("common.rangeInput")],
        ["rate", t("common.rating")],
        ["cascader", t("common.cascader")],
        ["cascader-multiple", t("common.cascaderMultiple")],
        ["live-search", t("common.ajaxLiveSearch")],
        ["live-search-multiple", t("common.ajaxLiveSearchMultiple")],
      ]);
      return layout.get(String(cellValue));
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getModelGroupData();
      updateModelList();
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      modelId,
      modelsTableData,
      submit,
      deleteCapabilityModels,
      handleAddModel,
      handleEditModel,
      updateModelList,
      handleDeleteModel,
      getModelLayoutLabel,
    };
  },
});
